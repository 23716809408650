import React, { useState, useCallback } from 'react';
import './App.css';
import { FiPaperclip, FiX } from 'react-icons/fi';

function App() {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState('');
  const [result, setResult] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [dragOver, setDragOver] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());
  const [conversationHistory, setConversationHistory] = useState([]);

  const removePreview = () => {
    setPreview('');
    setFile(null);
    document.getElementById('fileUpload').value = ''; // Reset file input element
    setInputKey(Date.now()); // Reset input key to force re-render
  };


  const callGPT4API = async (message) => {

    const data = {
      model: "gpt-4",
      messages: [
        ...conversationHistory,
        {
          role: "system",
          content: "Do not mention OpenAI, you will recieve image descriptions do not say based on your description, just reply to said image  your name is MEDIC is designed to provide guidance in medical emergencies and general health advice with an emphasis on brevity and clarity. In emergency situations, MEDIC offers clear, step-by-step first aid instructions and basic life support information, maintaining a calm and reassuring tone. It highlights the need for professional medical attention in severe cases and guides users to emergency contacts and resources efficiently. For non-emergency health queries, HEALER provides wellness advice on lifestyle, disease prevention, and mental health, always underlining the importance of consulting healthcare professionals. Responses are informative, empathetic, and maintain calmness, ensuring critical information is conveyed effectively without unnecessary elaboration"
        },
        {
          role: "user",
          content: message
        }
      ],
      temperature: 1,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0
    };

    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
        },
        body: JSON.stringify(data)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const apiResponse = await response.json();
      return apiResponse.choices && apiResponse.choices.length > 0
        ? apiResponse.choices[0].message.content
        : "Sorry, I couldn't generate a response.";
    } catch (error) {
      console.error('Error:', error);
      return `An error occurred while fetching the response: ${error.message}`;
    }

  };

  const sendToGPT4Vision = async (imageURL) => {
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
        },
        body: JSON.stringify({
          model: "gpt-4-vision-preview",
          messages: [
            {
              role: "user",
              content: [
                {
                  type: "text",
                  text: "What’s in this image?"
                },
                {
                  type: "image_url",
                  image_url: {
                    "url": imageURL
                  }
                }
              ]
            }
          ],
          max_tokens: 300
        })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const apiResponse = await response.json();
      return apiResponse.choices && apiResponse.choices.length > 0
        ? apiResponse.choices[0].message.content
        : "Sorry, I couldn't generate a response.";
    } catch (error) {
      console.error('Error in sendToGPT4Vision:', error);
      return 'Failed to analyze image.';
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      setPreview(URL.createObjectURL(selectedFile));
    }
  };

  const handleSubmit = async () => {
    if (isSubmitting || !file) {
      return;
    }
  
    setIsSubmitting(true);
    setStatusMessage('Analyzing image...');
    setUploadProgress(10);
  
    const reader = new FileReader();
  reader.onload = async () => {
    const imageURL = reader.result;
    updateConversationHistory('user', imageURL); // Debugging log
  
      try {
        console.log('Sending request to GPT-4 Vision'); // Debugging log
        const analysisResult = await sendToGPT4Vision(imageURL);
        console.log('Analysis Result:', analysisResult); // Debugging log
  
        if (analysisResult && analysisResult.trim()) {
          updateConversationHistory('user', analysisResult);
  
          const chatResponse = await callGPT4API(analysisResult);
          console.log('GPT-4 response:', chatResponse); // Debugging log
  
          if (chatResponse && chatResponse.trim()) {
            updateConversationHistory('system', chatResponse);
            setMessages(prevMessages => [
              ...prevMessages,
              { role: 'user', content: analysisResult },
              { role: 'system', content: chatResponse }
            ]);
          }
          
          setStatusMessage('Analysis and chat response complete.');
        } else {
          setStatusMessage('No valid analysis result received.');
        }
      } catch (error) {
        console.error('Error during image analysis and chat:', error);
        setStatusMessage('Failed to process the image or chat response.');
      } finally {
        setUploadProgress(0);
        setIsSubmitting(false);
        removePreview();
      }
    };
  
    reader.onerror = () => {
      console.error('Error reading the file');
      setStatusMessage('Error reading the file.');
      setUploadProgress(0);
      setIsSubmitting(false);
    };
  
    reader.readAsDataURL(file);
  };
  
  
  const updateConversationHistory = (role, content) => {
    setConversationHistory(prevHistory => [...prevHistory, { role, content }]);
  };

  
  const sendMessage = async () => {
    if (!newMessage.trim()) return;

    updateConversationHistory('user', newMessage);

    try {
      const response = await callGPT4API(newMessage);
      setMessages(prev => [...prev, { role: 'system', content: response }]);
      updateConversationHistory('system', response);
    } catch (error) {
      console.error('Error in sendMessage:', error);
      // Handle the error appropriately here
    }

    setNewMessage(''); // Clear the message input field
    

  };

 

  const handleButtonClick = async () => {
    if (file) {
      await handleSubmit(); // If an image is selected, analyze the image
    } else if (newMessage.trim()) {
      await sendMessage(); // If there's text in the input, send the message
    }
  };

  const getButtonLabel = () => {
    if (file) return 'Analyze Image';
    return 'Send';
  };

  return (
    <div className="App">
      <div className="chat">
        <h1>Chat with MEDIC</h1>
        <p>Powered by CONNECTX</p>
        <div className="chat-container">
          {console.log("Rendering conversation history:", conversationHistory)}
          {conversationHistory.length > 0 ? (
            conversationHistory.map((msg, index) => {
              if (msg.content.startsWith('data:image')) { // Check if the content is an image URL
                return (
                  <div key={index} className={`message ${msg.role}`}>
                    <img src={msg.content} alt="Uploaded" style={{ maxWidth: '100%' }} />
                  </div>
                );
              } else {
                return (
                  <div key={index} className={`message ${msg.role}`}>
                    {msg.content}
                  </div>
                );
              }
            })
          ) : (
            <p>No messages yet.</p>
          )}
        </div>
  
        {preview && (
          <div className="image-preview-container">
            <img src={preview} alt="Preview" className="image-preview" />
            <button onClick={removePreview} className="remove-image-button">
              <FiX /> {/* 'X' icon */}
            </button>
          </div>
        )}
  
        <div className="message-input">
          <input
            id="fileUpload"
            type="file"
            onChange={handleFileChange}
            accept="image/*"
            style={{ display: 'none' }}
          />
          <label htmlFor="fileUpload" className="attach-button">
            <FiPaperclip /> {/* Paperclip icon */}
          </label>
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message"
          />
          <button onClick={handleButtonClick} disabled={isSubmitting} className="send-analyze-button">
            {getButtonLabel()}
          </button>
        </div>
      </div>
  
      {statusMessage && <p className="status-message">{statusMessage}</p>}
      {uploadProgress > 0 && (<progress value={uploadProgress} max="100"></progress>)}
    </div>
  );
  
}

export default App;
